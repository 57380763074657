.parent-container {
  /* grid-column: span 10; */
  max-height: 100%;
  height: 100%;
  /* background: #222; */
  box-sizing: border-box;
  padding: 0;
  margin:0;
  z-index:2;
}

.parent-container-light {
  /* Styles for light mode */
  background-color: yellow; /* Example light background color */
}

.parent-container-dark {
  /* Styles for dark mode */
  background-color: #222222; /* Example dark background color */
}

.draggable-container {
  height: 100%;
  padding: 0;
  margin:0;
}
.parent_container-relative {
  border-radius: 25px;
  height: 25px;
  width: fit-content;
  overflow: hidden;
  position: absolute;
  top: 5px;
  right: 6px;
  transition: opacity 0.3s; 
  cursor: pointer; 
  align-items: center;
  justify-content: center;
}

.parent_container-relative button {
  background-color: transparent;
  color: inherit;
  border: none;
  cursor: pointer;
  font-weight: bold;

}
/* .draggable-container {
  display: inline-block;
} */
/* .parent-container:hover{
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  transform: scale(1.1);
  transition: all .3s ease-out;
  z-index: 12;
} */