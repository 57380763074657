/* .currency-input-container{
  margin-right: 2rem;
}

.currency-input-container button{

}
.currency-input_wrapper{
  display: inline-flex;
  max-width: 100px;
}
.currency-input_input-field{
  display:flex;
} */

.currency-input-container {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  /* margin-right:1rem; */
}

.currency-input-container p{
  margin: 0;
  left:0;
}

.currency-input_wrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.currency-input_input-field {
  flex-grow: 1;
  border: none;
  border-bottom: 1px solid var(--text-primary);
  outline: none;
  text-align: center;
  max-width:5.5em;
  background-color: var(--background);
  color: var(--font-color-light);
}
/* 
[data-theme='light'] .currency-input_input-field {
background-color: var(--background-color-secondary-light);
color: var(--font-color-light);
}

[data-theme='dark'] .currency-input_input-field {
background-color: var(--background-color-secondary-dark);
color: var(--font-color-dark);
} */

/* .currency-input_submit-button {
  aspect-ratio: 2/1;
  margin-left:0.5em;

  border: none;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  height: fit-content;
}  */

.currency-input_wrapper_left {
  flex-direction: column; /* Stack items on top of each other on smaller screens */
  align-items: flex-start;

}
.currency-input_wrapper_left p{
  margin:0;

}



.currency-input_submit-button {
  position: relative;
  margin-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  height: fit-content;
  background: white;
  color: #2BCB96;
  border: 2px solid #2BCB96;
  border-radius: 10px;
  padding: 10px 20px;
  overflow: hidden;
}


.currency-input_submit-button.do-submit {
  color: white;
  background: #2BCB96;
}

