.overlay {
  position: sticky;
  left: 0;
  top: 0;
  z-index: 999;
}

.overlay_background {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100svw;
  height: 100svh;
  cursor: pointer;
  overflow-y: auto;

  /* -moz-selection {
    background: #AFAFAF;
  }
  -webkit-selection {
    background: #AFAFAF;
  }
  selection {
    background: #AFAFAF;
  } */

}

.overlay_background::selection {
  background-color: transparent; /* For most browsers */
}

.overlay_background::-moz-selection {
  background-color: transparent; /* For Firefox */
}
.overlay_background::-webkit-selection {
  background-color: transparent; 
}


.overlay_container {
  background-color: var(--background);
  position: absolute;
  /* Change from relative to fixed */
  border-radius: 8px;
  /* margin: auto; */
  margin: 0;
  z-index: 10;
  padding: 25px;
  width: fit-content;
  max-width: 80%;
  /* You can adjust the width as needed */
  max-height: 80%;
  /* You can adjust the height as needed */
  overflow-y: auto;
  min-width: 200px;
  top: 50%;
  /* Center vertically */
  left: 50%;
  /* Center horizontally */
  transform: translate(-50%, -50%);
  /* Center the container */


   /* @media(max-width: 825px) {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  } */

  @media(max-width: 510px) {
    padding:10px;
    min-width: 90%;
  }
}

.overlay_close {
  border: none;
  background-color: transparent;
  cursor: pointer;
  position: absolute;

  right: 5px;
  top: 5px;
}

[data-theme='light'] .overlay_close {

  color: var(--font-color-light);
}

[data-theme='dark'] .overlay_close {
  color: var(--font-color-dark);
}

.overlay_close:after {
  display: inline-block;
  content: "\00d7";
  font-weight: 1000;
  font-size: large;
}