.basic-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px;
  border: 1px solid #ccc;
}

.message-container {
  display: flex;
  flex-direction: column;
}

.top-message-container {
  font-weight: bold;
  text-align: left;
}

.display-message-container {
  margin-top: 5px;
}

.icon-container {
  margin-left: 10px;
  font-size: 48px;
  color: red;
}