.piechart-container {
  height: 100%;
  display: flex;
  align-items: center;
}

.piechart-container .recharts-wrapper>svg {
  width: initial !important;
  height: initial !important;
}

.piechart-container .recharts-legend-wrapper {
  position: initial !important;

}

.piechart-container .recharts-legend-item {
  /*.recharts-legend-item */
  max-width: 230px;
  /* Adjust as needed */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5rem;
}

@media (max-width: 401px) {
  .piechart-container .recharts-wrapper>svg {
    transform: scale(.75);
  }

  .piechart-container .recharts-legend-item {
    max-width: 160px;
  }
}
@media (max-width: 450px) and (min-width: 402px) {
  .piechart-container .recharts-legend-item {
    max-width: 180px;
  }
}
@media (max-width: 590px) and (min-width: 451px) {
  .piechart-container .recharts-legend-item {
    max-width: 220px;
  }
}


@media (max-width: 1115px) and (min-width:  591px) {


  .piechart-container .recharts-legend-item {
    max-width: 300px;
  }
}


@media (min-width: 1116px) {
  .piechart-container .recharts-wrapper>svg {
    transform: scale(1.2);
  }
}