.faq {
  /* width: 100%; */
  justify-content: center;
  text-align: center;
  min-width: 280px;
  min-height: 280px;
  width:600px;
  margin-top: 1.5rem;
}
@media (max-width: 1050px) {
  .faq{
    width:400px;
  }
}
@media (max-width: 550px) {
  .faq{
    width:100%;
  }
}
.faq-subtitle {
  display:flex;
  text-align: left;
  margin-left: 1rem
}


.faq .accordion-item {
  background: var(--background); /* Set the default background color */
}

.faq .accordion-item:nth-child(odd) {
  background: var(--table-background-alt); /* Set the background color for even-numbered items */
}