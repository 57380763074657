.horizontal_barchart-container{
  margin-left: 10px;
    margin-right: 20px;
    margin-bottom: 60px;
  @media (max-width: 550px) {
    margin-left:0rem;
  }
  
}

/* .recharts-tooltip-wrapper {
  color: initial !important;
} */

.custom-tooltip{
  position:relative;
  padding: 2px;
  border-radius: 4px;
  z-index: 5;
  /* top: 4rem; */
  padding-inline:1rem;
  text-align: left;
  width:fit-content;
  /* width: 100%; */
  min-width: 8rem;
  height:fit-content;
  background-color: rgba(0,0,0,8);
  color: white;
  opacity: 0.8;
  pointer-events: none;

  bottom: 40px;
  left: 40px;

  max-width: 400px;

  /* right: -15px;
  top: calc(100% + 120px); */
}
.custom-tooltip-vertical{
  position:relative;
  padding: 2px;
  border-radius: 4px;
  z-index: 5;
  /* top: 4rem; */
  padding-inline:1rem;
  text-align: left;
  width: fit-content;
  min-width: 8rem;
  height:fit-content;
  background-color: rgba(0,0,0,8);
  color: white;
  opacity: 0.8;
  pointer-events: none;

  top: 0px;
  left: 0px;

  white-space:nowrap;
}
/* 
[data-theme='light'] .custom-tooltip {
  background-color: rgba(0,0,0,8); ;
  color: var(--font-color-dark);
}

[data-theme='dark'] .custom-tooltip {
  background-color: var(--background-color-dark);
  color: var(--font-color-dark);
} */


.custom-tooltip-vertical p,
.custom-tooltip p {
  margin-left:0.5rem;
}
.custom-tooltip-vertical li,
.custom-tooltip li {
  margin:0;
  margin-left: -1rem;
  max-width: 200px;
  /* overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; */
}


/* 
[data-theme='light'] .recharts-tooltip-wrapper  {
  background-color: var(--background-color-secondary-light) !important;
  color: var(--font-color-light) !important;
}

[data-theme='dark'] .recharts-tooltip-wrapper  {
  background-color: var(--background-color-secondary-dark) !important;
  color: var(--font-color-dark) !important;
} */

