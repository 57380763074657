.small_component-container{
  display: inline-flex;
  justify-content: space-between;
  background-color: transparent;
  align-items: center;
  text-align: right;
  font-size: 18px;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 1.25rem;
}
.small_component-content{
  margin-top: 1rem;
  margin-left: 1rem;
  font-weight: 1000;
  font-size: medium;
  
}
.small_component-icon{
  font-weight: 1000;
  font-size: 46px;
}
.small_component-info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 10px;
}

/* .title-container:first-child {
  margin-bottom: 1.25rem;
}
.title-container:last-child {
  margin-top: 1.25rem;
} */

.bold-font{
  font-weight: bold;
}

.positive {
  color: green; /* Color for positive percentage */
}

.negative {
  color: red; /* Color for negative percentage */
}
.none {
  display:none;
}
.neutral {
  color:var(--text-primary)
}

/* .has_tooltip{
  position: relative;
} */

.small-component_base-container {
  justify-content: space-between;
}