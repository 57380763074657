.error-container {
  display: grid;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  /* grid-column: span 1;
  grid-row: span 1; */
}

.error-container h4{
  margin: auto;
  justify-content: center;
}