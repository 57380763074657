
.toggle-switch-control.light .sun-icon {
  position: absolute;
  right: 2px; 
}

.toggle-switch-control.dark .moon-icon {
  position: absolute;
  left: 2px;
}

.sun-icon {
  color: var(--icon-yellow);
  margin-right: 2px;
  font-size: 22px;
}

.moon-icon {
  color: var(--icon-yellow); 
  margin-left:2px;
  font-size: 22px;
}