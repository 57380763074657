.vertical-barchart-container {
  margin-right: 20px;
  margin-left: 15px;
  margin-bottom: 15px;
  margin-top: 15px;
  text-overflow:initial;

  @media (max-width: 768px) {
    /* Adjust margin for devices with a screen width of 768 pixels or smaller */
    margin-right: 10px; /* Default margin value for larger devices */
    margin-left: 0px;
  }
}
.vertical-barchart-container .recharts-label-list{
  width:fit-content;
  background-color: blue;
  margin-right:20px;
  
}
.vertical-barchart_legend {
  margin-left: 20px;
  padding-bottom: 20px;
}
.ul.rechart-default-legend li {
margin-left: 20px;/* Common styling for all legend items */
}
