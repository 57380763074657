fieldset {
  margin: 8px;
  border: 1px solid silver;
  padding: 8px;    
  border-radius: 4px;
}

legend {
  margin: 2px 2px 2px 10px;
  font-weight: 600;
  text-align: left;    
}
.component-groups_explination{
  text-align: left;
  margin-left: 1rem;
}