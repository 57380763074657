.toggle-switch-control {
  position: relative;
  width: 70px;
  min-width: 70px;
  height: 30px;
  border-radius: 15px;
  border: 2px solid #ccc;
  cursor: pointer;
  /* background-color:#ccc; */
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  background-color: var(--toggle-switch-background-unchecked);

}

.toggle-switch-slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 15px;
  transition: 0.4s;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px;
}

.toggle-switch-slider:before {
  position: absolute;
  content: '';
  height: 23px;
  width: 23px;
  left: 2px;
  bottom: 2px;
  background: var(--toggle-switch-slider-unchecked);
  /* background-color: red; */
  border-radius: 50%;
  transition: 0.4s;

}
.toggle-switch-control.dark .toggle-switch-slider:before {
  left: calc(100% - 25px);
  background-color: var(--toggle-switch-slider-checked);
}

.toggle-switch-control.dark {
  background-color: var(--toggle-switch-background-checked); 
}
