.componentlist_container {
  z-index: 10;
  min-width: 280px;
  min-height: 280px;
  width: 800px;
}

.checkbox-container {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap on smaller screens */
  gap: 8px; /* Add spacing between items */
  padding: 0;
  list-style: none;
}

.list-item {
  flex: 1 1 calc(50% - 16px); /* Two columns with spacing */
  display: flex;
  align-items: center;
}

@media (max-width: 1050px) {
  .componentlist_container {
    width: 400px;
  }

  .list-item {
    flex: 1 1 100%; /* Switch to single-column on smaller screens */
  }
}

@media (max-width: 550px) {
  .componentlist_container {
    width: 100%;
  }
}

.componentlist-label {
  display: flex;
  align-items: center;
  gap: 8px; /* Add spacing between icon and text */
  border: 1px solid #ccc;
  padding: 8px;
  cursor: pointer;
  flex: 1;
  margin-bottom: 4px;
}

.componentlist-label:hover {
  background: var(--background-secondary);
}

.component-icon {
  width: 30px;
  height: 30px;
  object-fit: contain; /* Ensure the image fits nicely */
}

.list-item input[type="checkbox"] {
  cursor: pointer;
}
