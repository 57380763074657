.action-container {
  width: 100%;
  height: 4rem;
  margin-top: 18px auto;
  padding: 0.5rem auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: sticky;
  top: 0; 
  z-index: 120; 
  background-color: var(--background);
  border-bottom: 2px solid #eaeaea;
  border-radius: 2px;
}

.action-buttons {
  display: flex;
  align-items: center;
}

.action-container-button {
  margin-left: 0.5rem;
  margin-left: 0.5rem;
  /* height: 35px; */
  /* width: 35px; */
  min-width: fit-content;
  font-size: 30px;
  border: none;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: var(--text-primary);
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.3s ease;

}
.action-container-button:hover{
  background-color: var(--background-secondary);
}
.action-container-burger_button{
  color: var(--text-primary);
  background-color: var(--background);
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  min-width: 180px;
  text-transform: capitalize;
  justify-content: space-between;
  align-items: center;
  display:inline-flex;
  /* margin: 0 1rem; */
  cursor: pointer;
}
.action-container-burger_button{
  font-weight: 500;
}
.action-container-burger_button p{
  margin-left:0.5rem;
  justify-content: center;
}
.action-container-burger_button svg {

  justify-content: center;
  margin-right:0.5rem;

}

@media (max-width: 450px) {
  .action-container-button {
    /* height: 35px; */
    /* width: 35px; */
    font-size: 24px;
  }
}

.notification-icon {
  position: absolute;
  top: -8px;
  right: -16px;
  background-color: #4c5db0;
  color: white;
  padding: 0.5em 0.5em;
  border-radius: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: 700;
  white-space: nowrap;
  min-width: fit-content;
  .separator {
    margin-inline: 0.2em;
  }
}


[data-theme='dark'] .action-container-button {
  color: var(--font-color-dark);
}


.action-buttons_burger-menu {
  margin-left: 1rem;
  display: none;
  position: relative;
  padding-right:2rem;
}

.action-buttons_burger-menu svg {
  cursor: pointer;
}

.action-buttons_burger-menu_container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  
  text-align: end;
  background: var(--color-footer);
  margin-top:3rem;
  position: absolute;
  right: 0;
  z-index: 10;
  /* margin-top: 1rem; */
  width:fit-content;
  border-radius: 5px;
  border-color: var(--background-secondary);
  box-shadow: 0px 0px 5px rgba(139, 139, 139, 0.879);
}

.action-buttons_burger-menu_container p {
  margin: 1rem 1rem, 0;
}

.action-buttons_burger_dark-mode {
  display: none;
}


@media screen and (max-width: 1050px) {
  .action-buttons {
      display: none;
  }
  .action-container {
    margin-right: 10em;
    justify-content: flex-end;
    align-items: center;
  }

  .action-buttons_burger-menu {
      display: flex;
  }

  .action-buttons_burger_dark-mode{
    display: flex;
  }
}

@media screen and (max-width: 700px) {
  .action-container {
    margin-right: 10em;

  }
}


/* 
  .gpt3__navbar-sign {
      display: none;
  } */


  /* .gpt3__navbar-menu_container-links-sign {
      display: block;
  } */
/* } */

  /* .current {
    transform:translateY(calc(var(--offset-margin) * -1));
  }

  .total {
    transform: translateY(var(--offset-margin));
  } */

  /* .separator {
    min-width: .75em;
    height: 100%;
    position: relative;
    display: inline-block;

    &::after {
      --height: 1.5em;

      position: absolute;
      content: " ";
      top: calc(50% - (var(--height) / 2));
      width: 1px;
      height: var(--height);
      background: #fff;
      transform: rotate(20deg);
    } */
  /* } */

  
/* [data-theme='light'] .action-container {
  background-color: var(--background-color-light);
}

[data-theme='dark'] .action-container {
  background-color: var(--background-color-dark);
}*/

/* .notification-icon {
  position: absolute;
  top: -14px;
  right: -12px;
  background-color: #4c5db0;
  color: white;
  padding: .33em;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 8px;
  font-weight: 600;
  white-space: nowrap;
  aspect-ratio: 1/1;
  min-width: fit-content;

  --offset-margin: 0.5em;

  .current {
    transform:translateY(calc(var(--offset-margin) * -1));
  }

  .total {
    transform: translateY(var(--offset-margin));
  }

  .separator {
    min-width: .75em;
    height: 100%;
    position: relative;
    display: inline-block;

    &::after {
      --height: 1.5em;

      position: absolute;
      content: " ";
      top: calc(50% - (var(--height) / 2));
      width: 1px;
      height: var(--height);
      background: #fff;
      transform: rotate(20deg);
    }
  }
} */
