.footer-container {
  max-width: 1600px;
  width: 100%;
  height: 4rem;
  color: grey; 
  /* background-color: white;
   */
  display: flex;
  position: relative;
}
.copyright-container{
  position: absolute;
  /* background-color: white; */
  margin-right:2rem;
  margin-left:1rem;
  top: 70%;
  font-weight: 600;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  
}
