.tooltip-container {
  top: 8rem;
  position: absolute;
  display: none;
  width: fit-content;
  height:fit-content;
  min-width: 20px;
  min-height: 20px;
}
/* .tooltip-children {
  position: relative;
} */

.tooltip{
  position:absolute;
  background-color: rgba(0,0,0,8);
  color: white;
  padding: 10px;
  border-radius: 4px;
  z-index: 5;
  transition: opacity 0.3s, visibility 0.3s;
  pointer-events: none;
  /* opacity: 0.9; */
}
/* 
.has_tooltip:hover .tooltip-container {
  display: initial;
  size: 1.5;
} */