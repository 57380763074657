* {
  box-sizing: border-box;
}

/* :root {
  --background-color-light:#fff;
  --font-color-light: black;
  --background-color-dark: #222222;
  --font-color-dark: #ffffff;
  --background-color-secondary-light: #efeded;
  --background-color-secondary-dark:#9b9c9cbb ;
} */
:root {
  --background: #fff;
  --background-secondary: #efeded;
  --text-primary: black;
  --text-secondary: #ced2d2e9;
  --accent: darkred;
  --border: #ccc;
  --table-background-alt: #dde5e5;
  --toggle-switch-background-unchecked: #dddde4;
  --toggle-switch-slider-unchecked: #fff;
  --toggle-switch-background-checked: #4ed164e5;
  --toggle-switch-slider-checked: #fff;

  --icon-yellow: #f0c200;
  --error-color: rgb(226, 27, 5);
  --good-green-color: #51c163f0;
}

[data-theme='dark'] {
  --background: #222222;
  --background-secondary: #60626289;
  --text-primary: #ffffff;
  --text-secondary: #3c3d3dd1;
  --accent: darkred;
  --border: #ccc;
  --table-background-alt: rgba(110, 110, 110, 0.203);
  --toggle-switch-background-unchecked: #9b9c9cbb;
  --toggle-switch-slider-unchecked: #D3D3D3;
  --toggle-switch-background-checked: #663399;
  --toggle-switch-slider-checked: #D3D3D3;
  --error-color: red;
}

html,
body {
  font-family: "Calibri", sans-serif;
  line-height: 1.4em;
  width: 100%;
  font-size: 16px;
  display: flex;
  padding: 0;
  margin: 0;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  background-color: var(--background-color-light);
  color: var(--font-color-light);
}

.base-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  justify-content: space-between;
  box-sizing: border-box;
  background-color: var(--background);
  color: var(--text-primary);
  overflow:clip;
}

.title-container {
  width:80%;
  height: 48px;
  margin-top: 0.5rem;
  margin-left: 0.75rem;
  margin-bottom: 0.25em;
  margin-right: 0.5rem;
  font-size: 1.25rem;
  font-weight: 800;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
}

.ranking_title-container {

  /* max-width: 100px; */
  /* justify-content: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; */
}

/* .base-container:hover{
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  transform: scale(1.1);
  transition: all .3s ease-out;
  z-index: 2;
} */