
.connections-container{
  columns:1;
  min-width: 280px;
  min-height: 280px;
  width:600px;
  margin: 0;
  padding-inline-start: 0px;
  text-align: left;
  z-index:12;
}

@media (max-width: 1050px) {
  .connections-container{
    width:550px;
  }
}
@media (max-width: 800px) {
  .connections-container{
    width:450px;
  }
}
@media (max-width: 650px) {
  .connections-container{
    width:300px;
  }
  
}
@media (max-width: 500px) {
  .connections-container{
    width:100%;
  }
  .connections-list-item-status{
    display: none;
  }
}

.connections-list-item {
  list-style-type: none;
  display: flex; 
  align-items: left;
  flex: 1;
  justify-content: space-between;
}
.connections-list-item-status{
  margin-right: 20px;
  align-self: center;
  font-weight: 600;
}
.connections-label {

  margin:8px;
  display:inline-flex;
  cursor:pointer;
  flex: 1;
  align-items: center;

 }
 label:hover {
  background:var(--background-secondary);
 }

 .connections-list-item:nth-child(n) {
  background: var(--table-background-alt);
}

.connections-list-item:nth-child(2n){
  background: var(--background);
}
