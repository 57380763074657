.close-button-container{
  border-radius: 25px;
  height: 25px;
  width: 25px;
  overflow: hidden;
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  transition: opacity 0.3s;  
  
}
.close-button-container.hidden {
  opacity: 0; /* Set opacity to 0 when hidden */
  pointer-events: none; /* Disable pointer events when hidden */
}


.close-button{
  color:black;
  justify-content: center;
  align-items: center;
  display:flex;
  font-size:24px;
  height: 24px;
  width: 24px;
  background-color: transparent;
  overflow: hidden;
  border: none;
  margin:auto;
  z-index: 999;
}
[data-theme='light'] .close-button {
  /* background-color: var(--background-color-secondary-light); */
  color: var(--font-color-light);
}

[data-theme='dark'] .close-button {
  /* background-color: var(--background-color-secondary-dark); */
  color: var(--font-color-dark);
}

.close-button:hover {
  cursor: grab;
  font-weight: 800;
}