.speedometer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.speedometer-title-container {
  text-align: center;
  margin-bottom: 10px;
  color: inherit;
}

.speedometer-wrapper {
  width: 100%;
  max-width: 300px;
  min-width: 200px;
  height: auto;
  max-height: 300px;
  min-height: 200px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(1);
  /* Default scale */
  transition: transform 0.3s ease-in-out;
}

@media (max-width: 856px) and (min-width: 815px) {
  .speedometer-wrapper {
    transform: scale(0.9);
    /* Scale down slightly */
    transform-origin: top;
    overflow: visible;
  }
}

@media (max-width: 591px) and (min-width: 401px) {
  .speedometer-wrapper {
    transform: scale(0.77);
    /* Further scale for smaller screens */
    transform-origin: top;
    /* Ensures scaling happens from the center */
    overflow: visible;
  }

  .speedometer-title-container {
    height: auto;
  }
}

@media (max-width: 400px) {
  .speedometer-wrapper {
    transform: scale(0.6);
    /* Further scale for very small screens */
    transform-origin: top;
  }
}