.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem;
  color: #000000;
}

.header-logo {
  flex: 1; /* Occupies most of the width */
}

.header-logo img {
  max-width: 100%;
  height: auto; /* Maintain aspect ratio */
}

.header-timestamp {
  flex: 0 0 8%; /* Takes up approximately 5% of the width */
  text-align: right;
}

.header-timestamp p {
  margin: 0; /* Remove default paragraph margin */
  font-weight: 800;
  color: grey;
}

@media screen and (max-width: 1300px) {
  .header-container {
    flex-flow: column; /* Stack elements vertically */
    align-items: flex-end; /* Align items to the left */
  }

  .header-logo {
    width: 100%; /* Logo takes full width */
    margin-bottom: 1rem; /* Add spacing between logo and timestamp */
  }

  .header-timestamp {
    width: 100%; /* Timestamp takes full width */
    text-align: right;
  }
}