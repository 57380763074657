.accordion-item{
  text-align: left;
  padding: 0.5rem;
  font-size: medium;
  font-weight: 800;
  border-radius: 4px;
  max-width: 1028px;
}
.question{
  font-size: large;
  border-bottom: 1px solid var(--text-primary);

}
.answer{
  margin-top: 1rem;
  font-size: medium;
}