.login-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* .login-info-container {
  display: inline-flex;
  align-items: center;
  text-align: center;
} */
.login-info-container {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  /* Add space between elements */
  width: 100%;
  /* Align items properly */
}

.info-btn {
  display: flex;
  /* Center the text inside the button */
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  border-radius: 50%;
  /* Make it a circle */
  width: 30px;
  /* Ensure it's a perfect circle */
  height: 30px;
  /* Ensure it's a perfect circle */
  font-size: 30px;
  /* Adjust font size */
  color: white;
  /* White text */
  background-color: #007bff;
  /* Blue background */
  border: none;
  /* Remove border */
  cursor: pointer;
  /* Change cursor on hover */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  /* Add a subtle shadow */
  transition: transform 0.2s ease;
  /* Add hover effect */
}

.info-btn:hover {
  transform: scale(1.1);
  /* Slightly enlarge the button on hover */
}

.info-btn:focus {
  outline: none;
  /* Remove the focus outline */
}

@media (max-width: 550px) {
  .info-btn {
    background-color: #007bff;
    /* Ensure consistent color on smaller screens */
    margin-left: 10px;
  }
}

/* 
.info-btn {
  margin-left: 20px;
  border-radius: 20px;
  width: 25px;
  height: 25px;
  font-size: 20px;
  color: white;
  background-color: #007bff;
  border: 0px solid #ccc;
}


@media (max-width: 550px) {
  .info-btn {
    background-color: transparent;
  }
} */

.info-text {
  margin-top: 10px;
}

.login_input-container {
  margin-bottom: 15px;
}


.login_input-container input[type="text"],
.login_input-container input[type="password"] {
  width: calc(100% - 16px);
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.login_remember-me-container {
  display: inline-flex;
  margin-bottom: 10px;

  input {
    margin-right: 8px;
  }
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
  text-align: left;
}

.submit-btn {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: Large;
  position: relative;
  overflow: hidden;


}

.submit-btn:hover {
  background-color: #0056b3;
}

.error-message {
  color: red;
  margin-bottom: 10px;
}


.button-text {
  display: inline-block;
  transition: opacity 0.3s ease;
}

.loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader::after {
  content: '';
  width: 20px;
  height: 20px;
  border: 2px solid #fff;
  border-radius: 50%;
  border-top-color: transparent;
  animation: spin 0.6s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}