.welcome-page{
  max-width: 1028px;
  margin-inline: 1rem;
  min-width: 280px;
}
.welcome-page_title-container{
  width: 100%;
  margin-top: 0.5rem;
  margin-left: 1rem;
  margin-bottom: 0.25em;
  margin-right: 0.5rem;
  font-size: 2rem;
  font-weight: 800;

  align-items: center;
  text-align: center;
}
.welcome-page_body-container {
  display: block;
  margin-top: 2rem;
}
.welcome-page_body-container p{
  text-align: left;

}
.welcome-page_action-buttons{
  display: flex;
  min-width: 5rem;
  min-height: 2rem;
  width: 100%;
  height: auto;
  justify-content: space-between;
  color: #fff;

  .cancel-button, .accept-button{
    width: 5rem; 
    border: none; 
    cursor: pointer;
    font-weight: 800;
  }
  .cancel-button {
    background-color: var(--error-color); 
    color:inherit;
  }
  .accept-button {
    background-color: green; 
    color:inherit;
  }
}

