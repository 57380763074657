.details-table {
  display: flex;
  flex-direction: column;
  /* width: 100%; */
  min-width: 280px;
  /* min-height: 280px; */
  width: 100%;

  border: 1px solid var(--text-primary);
  border-radius: 4px;
  margin-top: 1.5rem;

  overflow-x: auto;
}

.details_table-row {
  display: flex;
  width: 100%;
  cursor:text;
}

.details_table-header {
  display: flex;
  font-weight: 800;
}

.details_table-header>.details_table-cell {
  text-align: center;
}

.details_table-cell {
  /* flex: 1; */
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  overflow: hidden;
  white-space: nowrap;

  margin-bottom: 7px;
  margin-top: 7px;
  justify-content: center;
  align-items: center;


}

.details_table-row:nth-child(even):not(.details_table-header) {
  background: rgba(110, 110, 110, 0.203);
}

.details_table-icon-cell {
  background-color: white;
  font-size: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
}

.details_table-icon-cell.error {

  color: red;
}

.details_table-icon-cell.success {
  color: green;
}

.details_table-row>.details_table-cell:nth-child(1),
.details_table-row>.details_table-cell:nth-child(2),
.details_table-row>.details_table-cell:nth-child(4) {
  min-width: 50px;
  max-width: 150px;
}

.details_table-row>.details_table-cell:nth-child(1) {
  margin-left: 0.5rem;
}

.details_table-row>.details_table-cell:nth-child(4) {
  margin-right: 0.5rem;
}

.details_table-row>.details_table-cell:nth-child(3) {
  flex-grow: 1;
  margin: auto 1rem;
  /*text-overflow: ellipsis;*/

}


@media (max-width: 1050px) {
  .details-table {
    width: 600px;
  }
}

@media (max-width: 800px) {
  .details_table-cell:last-child {
    display: none;
    width:400px;
  }
}

@media (max-width: 550px) {
  .details-table {
    width: 100%;
  }
}