/* .table_container {
  max-width: 750px;
  margin: 0 auto;
  overflow: auto;
} */
.caption {
  font-weight: 800;

}

.table {
  width: 100%;
  min-width: 800px;
  border-spacing: 0;
  height: 100%;

}
@media(max-width: 1180px) {
  .table {
    min-width: 600px;
  }
}
@media(max-width: 800px) {
  .table {
    min-width: 400px;
  }
}
@media(max-width: 560px) {
  .table {
    min-width: 360px;
  }
}

caption {
  font-style: italic;
  font-size: 120%;
  margin-bottom: 10px;
}

.table th {
  background: var(--background);
  padding: 8px 20px;
  justify-content: space-between;
  border-bottom: 1px solid #1a1a1a;
  font-weight: bold;
  /* text-align: left; */

}

.table tr {
  text-align: left;

}

.table th.up {
  background-image: url("./../../assets/tableassets/up_arrow.png");
}

.table th.down {
  background-image: url("./../../assets/tableassets/down_arrow.png");
}

.table th.default {
  background-image: url("./../../assets/tableassets/default.png");
}

th.up,
th.default,
th.down {
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center right;
}

.table td {
  border-top: 1px solid var(--background);  
  padding: 8px 20px;

}

.table tbody tr:first-child td {
  border-top: none;

}

.table tbody tr:nth-child(n) td {
  background: var(--table-background-alt);
}

.table tbody tr:nth-child(2n) td {
  background: var(--background);
}

.table thead tr th:first-child,
.table tbody tr td:first-child {
  min-width: 150px;

}