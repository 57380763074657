.component-container {
  /* background-color: #FAFBFB; */
  gap: 0.5rem;
  max-width: 1200px;
  padding: 0.5rem;
  background-color: var(--background-secondary);
  /* color: var(--text-primary); */
}

/* [data-theme='light'] .component-container{
  background-color: var(--background-color-secondary-light);
  color: var(--font-color-light);
}

[data-theme='dark'] .component-container {
  background-color: var(--background-secondary);
  color: var(--font-color-dark);
} */

.react-grid-layout{
  position: relative;
}
.grid-item {
  transition: transform 0.3s ease-out;
}
/* 
.grid-item.clicked {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  transform: scale(1.1);
  transition: all .3s ease-out;
  z-index: 7;
} */
.grid-item:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  /* transform: scale(1.05); */
  /* transition: all .3s ease-out; */
  z-index: 7;
}
/* .react-grid-item:hover{
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  transform: scale(1.1);
  transition: all .3s ease-out;
  z-index: 12;
} */