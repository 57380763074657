.simple-ranking-container table td:nth-child(1) {
  max-width: 150px;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.simple-ranking-container table thead tr th:last-child {
  text-align: right;
  padding-right: 1rem;
}

.simple-ranking-container table td,
.simple-ranking-container table th {
  padding-block: 0;
  margin-inline: 0;
  padding-inline: 0.5rem;
  /* margin-inline: .5em; */
}

.simple-ranking-container table {
  min-width: 200px;
  height: 74%;
}