/* .App{
  background-color: #FAFBFB;
  margin: 0 auto;
  column-gap: .5rem;
  row-gap: .5rem;
  display:center;
  width: 100%;
  padding: .5rem;
  align-items: center;
  justify-content: center;
} */

.App {
  padding: 0;
  text-align: center;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--background);
  color: var(--text-primary);
}

.no-scroll {
  overflow: hidden;
}

/* [data-theme='light'] .App {
  background-color: var(--background-color-light);
  color: var(--font-color-light);
} */

/* [data-theme='dark'] .App {
  background-color: var(--background-color-dark);
  color: var(--font-color-dark);
} */

.app-wrapper {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  background-color: var(--background);
  color: var(--text-primary);
}

/* [data-theme='light'] .app-wrapper {
  background-color: var(--background-color-light);
  color: var(--font-color-light);
}

[data-theme='dark'] .app-wrapper {
  background-color: var(--background-color-dark);
  color: var(--font-color-dark);
} */