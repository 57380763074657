.complex-ranking-container table thead tr th:last-child {
  text-align: right;
  padding-right: 1rem;
}

.complex-ranking-container table td,
.complex-ranking-container table th {
  padding-block: 0;
  margin-inline: 0;
  padding-inline: 0.5rem;

  /* margin-inline: .5em; */
}

.complex-ranking-container {
  white-space: nowrap;
}

.complex-ranking-container table {
  min-width: 200px;
  height: 74%;
}

/* td:nth-child(1)  */
tr>*:nth-child(1) {
  padding-left: 1rem;
  max-width: 200px;
  white-space: inherit;
  overflow-x: hidden;
  text-overflow: ellipsis;

}

tr>*:nth-child(2) {
  text-align: center;
}

tr>*:last-child {
  text-align: right;
}

/* .complex-ranking-container table {

  td,
  th {
    padding-block: 0;
    padding-inline: .5em;
    max-width: 100px;


  }

  tr>*:nth-child(1) {
    padding-left: 1rem;
    max-width: 200px;

  }

  tr>*:nth-child(2) {
    text-align: center;
  }

  tr>*:last-child {
    text-align: right;

  }

 
} */
@media (max-width: 550px) {
  .complex-ranking-container table {

    tr>*:nth-child(1) {
      padding-left: 0.5rem;
      max-width: 80px;
    }
  }

}