.cookie-consent-modal {
  position: fixed; /* Stay in place during scrolling */
  bottom: 0; /* Align to the bottom of the viewport */
  left: 0; /* Align to the left of the viewport */
  width: 100%; /* Span the full width of the viewport */
  background-color: #fff; /* Light background */
  padding: 20px; /* Padding inside the modal */
  box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1); /* Shadow to give depth, pointing upwards */
  border-radius: 8px 8px 0 0; /* Rounded corners on the top side */
  z-index: 1000; /* Ensure it sits above other content */
  box-sizing: border-box; /* Ensure padding is included in width */
}

.cookie-consent-button-container{
  text-align: center;

}
.cookie-consent-modal p {
  margin-bottom: 20px; /* Space between text and buttons */
  font-size: 16px; /* Readable text size */
  line-height: 1.5; /* Improve readability */
  text-align: center; /* Center-align the text */
}

.cookie-consent-modal button {
  margin-right: 20px; /* Space between buttons */
  background-color:#007bff;  /* Bootstrap's primary color for example */
  color: #ffffff; /* Light text on dark background */
  border: none; /* No border for simplicity */
  padding: 10px 20px; /* Padding for touch targets */
  cursor: pointer; /* Indicate clickable */
  border-radius: 5px; /* Match modal's rounded corners */
  min-width: 102px;

}

/* Additional styles for the decline button to differentiate */
.cookie-consent-modal button:nth-child(2) {
  background-color: #dc3545; /* Bootstrap's danger color for contrast */
}

/* Basic animation to attract attention */
@keyframes modalEntry {
  from {
    opacity: 0;
    transform: translate(-50%, -40%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

.cookie-consent-modal {
  animation: modalEntry 0.5s ease-out;
}